module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"459de2c069afbfdc2a5a21d53ca45e2d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ua"],"defaultLanguage":"ua","siteUrl":"http://localhost:3000","i18nextOptions":{"debug":true,"fallbackLng":"ua","supportedLngs":["ua","en"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/projects/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
